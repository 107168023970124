// React imports
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { config } from './Constants'
// import {StripeProvider} from "react-stripe-elements"

// Component imports
// import 'react-toggle/style.css';
import './index.scss';
import App from './App';
// Send a pageview for the initial load
const isLive = config.isLive
 if(isLive) {
   ReactGA.initialize('G-JJ35LKKPT6');
  // console.log('pageview sent index');
    // ReactGA.send('pageview');
 }
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>, // </StripeProvider>

  document.getElementById('root'),
);
