
import { Suspense, lazy, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Hearts } from 'react-loader-spinner'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { LoginProvider } from './Context/LoginContext'
import { SubscriptionStatusProvider } from './Context/SubscriptionStatusContext';
import ReactGA from 'react-ga4';
import { config } from './Constants'
import ShopLayout from './ShopLayout';
import ShopperRoutes from './ShopperAdminLayout';
import ScrollToTop from './components/Common/ScrollToTop';
const Header = lazy(() => import(/* webpackChunkName: 'Base' */'./components/Header/Header'));
const Footer = lazy(() => import(/* webpackChunkName: 'Base' */'./components/Footer/footer'));
const PreLogin = lazy(() => import(/* webpackChunkName: 'Base' */'./components/PreLogin/PreLogin'));
const Login = lazy(() => import(/* webpackChunkName: 'Base' */'./components/Login/Login'));
const Landing = lazy(() => import(/* webpackChunkName: 'Base' */'./components/Buyer/Landing'));

const Forgot = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Login/Forgot'));
const Reset = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Login/Reset'));
const ResetConfirm = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Login/ResetConfirm'));

//const GiftSubscription = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Buyer/Subscription/GiftSubscription'));
const GiftCompletePage = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Buyer/Subscription/GiftCompletePage'));
const Validate = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Profile/confirmemailpg'));
// const Confirmsignup = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Buyer/SignUp/confirmsignup'));
const ReturnPolicy = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Buyer/ReturnPolicy'));
const Notavailable = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/404/not-available'));
const SALayout = lazy(() => import(/* webpackChunkName: 'SA' */'./SAAdminLayout'));

const getLandingPage = () => {
  const seller = JSON.parse(localStorage.getItem('seller'));
  // console.log('getting landing page');
  if (seller) {
   //   console.log('sellerplace')
    if (seller.isSeller || seller.isAdmin) {
    //  conso'seller.isSeller'Seller", seller.isSeller);
      return <Navigate to="/shopper/listings" replace />;
    } else {
   //   console.log('going to sa listings')
      return <Navigate to="/sa/listings" replace />;
    }
  } else {
  //  console.log('going to landing page')
    return <Landing />;
  }
}

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    config.isLive && ReactGA.send('pageview');
    // console.log('pageview sent APP');
  }, [location]);
};
const App = () => {
  usePageTracking();
  
  return (
    <div className='app_wrapper'>
      <ScrollToTop />
      <Suspense fallback={(<div className="overlay"><Hearts color="#F05F40" height={100} width={100} /></div>)} >
        <LoginProvider >
          <CookiesProvider>
            <Header />
          </CookiesProvider>
          <div className="site-content">
            <Routes>
              <Route path="/" element={getLandingPage()} />
              <Route path="/not-available" element={<Notavailable />} />
              <Route path="/return-policy" element={<ReturnPolicy />} />
             
              <Route path='/prelogin' element={<PreLogin />} />
              <Route path='/login' element={<Login />} />
              <Route path='/forgot' element={<Forgot />} />
              <Route path='/reset/:resettoken' element={<Reset />} />
              <Route path='/resetConfirm/' element={<ResetConfirm />} />
              <Route path='/validateemail' element={<Validate />} />
              
              <Route path='/gift-subscription-complete' element={<GiftCompletePage />} />
              {/* <Route path='/confirmsignup' element={<Confirmsignup />} /> */}
              

              <Route path="/not-available" element={<Notavailable />} />
              <Route path="/return-policy" element={<ReturnPolicy />} />
              {/* Routes for admin shoppers */}
              <Route path='/shopper/*' element={<ShopperRoutes />} />
              {/* For SA  Create own Rounter, and context refrence useLogin, set state of use login in Route */}
              <Route path='/sa/*' element={<SALayout />} />
              {/* For the shop itself */}

              <Route path="/*" element={<SubscriptionStatusProvider><ShopLayout /></SubscriptionStatusProvider>} />
            </Routes>

          </div>

        </LoginProvider>
        <Footer />
      </Suspense >
    </div>

  )
}
export default App


